@font-face {
	font-family: "Mark W02 Medium";
	src: url("../fonts/5423443/03f00053-c3e6-4fd2-8a5b-cabb74ccf768.woff2") format("woff2"), url("../fonts/5423443/57b2b15c-8cde-47c5-9ee6-0fcdd8aafc2b.woff") format("woff"); }

@font-face {
	font-family: "Mark W02 Book";
	src: url("../fonts/5423519/3ead50c5-25cd-4b9d-a1f6-7a6b8c0cbefd.woff2") format("woff2"), url("../fonts/5423519/a27796bd-1d9e-4a23-bc97-b99f538e5e9f.woff") format("woff"); }

@font-face {
	font-family: "Mark W02 Light";
	src: url("../fonts/5423554/9fffb502-49ec-468f-97b8-c24fe0d581ce.woff2") format("woff2"), url("../fonts/5423554/ba03b0e5-67a2-4446-98e4-3d9ebbc32465.woff") format("woff"); }

@font-face {
	font-family: 'SignPainter Medium';
	font-style: normal;
	font-weight: normal;
	src: local('SignPainter Medium'), url('../fonts/SignPainterHouseScript.woff') format('woff'); }

body:not(.gr) {
	font-family: "Mark W02 Book";
	input,
	select,
	option,
	textarea,
	button,
	.result-title,
	.result-details {
		font-family: "Mark W02 Book"; }

	.how-icons p,
	section.features-benefits .content p {
		font-family: "Mark W02 Light"; }

	.free-trial-btn {
		font-family: "Mark W02 Medium"; } }

select {
	-moz-appearance: none; }

body.gr {
	font-family: 'proxima-nova'; }
